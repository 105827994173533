@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600&display=swap');

:root {
  --dark-blue: #080523;
  --purple: #4F2F70;
  --bright-purple: #b976ff;
  --lavender: #BB99E0;
  --near-white: #dedbe0;
  --green: #93d3a4;
}

html {
  background-color: var(--dark-blue);
}

html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header, section {
  margin-top: 16px;
  padding: 16px;
}

.main, header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1, h2 {
  text-align: center;
  margin: 0;
}

h1 {
  color: var(--near-white);
  font-size: 4rem;
  margin-bottom: 8px;
  text-shadow: 3px 3px 0 var(--purple);
}

h2 {
  color: var(--lavender);
  font-size: 2rem;
  margin-bottom: 16px;
}

a {
  color: var(--green);
}

p {
  margin: 0;
}

.graphic {
  align-self: center;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.line {
  border-bottom: 2px solid var(--purple);
  height: 16px;
  border-image: radial-gradient(at 10%, var(--near-white) 0%, var(--purple) 0%) 1;
}

.logo {
  height: 40vmin;
  margin-left: 1vmin;
}

.contact {
  color: var(--lavender);
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
  text-align: center;
}

.about {
  color: var(--near-white);
  font-size: 1.5rem;
  width: 80vmin;
}

.about:last-child {
  margin-top: 16px;
}

footer {
  margin: 0;
  padding: 32px;
  color: var(--bright-purple);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
  margin-top: 32px;
  background-color: black;
}

.dev-link {
  color: var(--bright-purple);
}

@media screen and (max-width: 560px) {
  footer {
    flex-direction: column;
  }

  footer :first-child {
    margin-bottom: 16px;
  }
}
