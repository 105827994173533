.profile-list {
    display: flex;
    justify-content: space-between;
    width: 80vmin;
}

.profile {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
}
